import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {MainLayout} from 'layouts'
import {CheckCircle, AnchorButton, LinkButton, LoadingSpinner, SEO, Testimonials} from 'components'
import {
  Check,
  PersonCareer,
  PersonPassion,
  PersonStudent,
  TriangleBlockHalf,
  TriangleBlockFull,
  TwoMonthsFree,
  AsteroidMobile,
  AsteroidDesktop,
  Stars1,
  Backpack,
} from 'images/svgs'
import {useAuth, useAmplitude} from 'hooks'
import BackgroundSVG from 'images/background-small.svg'

const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const BasicContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--content-width);
  }
`

const SectionTitleDiv = styled.div`
  margin: 25px 0;
  text-align: center;

  h2 {
    margin: 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const EyebrowText = styled.p`
  color: var(--blue);

  font-weight: bold;
`

const PricesSection = styled(BasicSection)`
  padding: 50px 0;
`

const PricesContent = styled(BasicContent)``

const RateToggle = styled.div`
  position: relative;
  background-color: var(--white);
  border: 2px solid var(--blue);
  border-radius: 5px;

  padding: 0;
  margin-bottom: 50px;
`

const DiscountArrow = styled(TwoMonthsFree)`
  z-index: -1;
  position: absolute;
  top: -45%;
  left: -15%;
`

const RateButton = styled.button`
  background: none;
  border: none;
  padding: 20px;
  margin: 0;
  width: 140px;

  color: ${({isSelected}) => (isSelected ? 'var(--white)' : 'var(--blue)')};
  background-color: ${({isSelected}) => (isSelected ? 'var(--blue)' : 'transparent')};
  transition: var(--transition);

  font-size: 1em;
  font-family: var(--secondary-font);
  font-weight: 600;

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
    }
  }
`

const RateButtonLeft = styled(RateButton)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`

const RateButtonRight = styled(RateButton)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`

const LoadingSection = styled.div`
  min-height: 572.2px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const PlansBox = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
  }
`

const PlanCard = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 0 20px;

  background-color: var(--white);
  border-radius: 5px;

  & > a {
    margin: 20px 0 0;
  }

  @media only screen and (min-width: 700px) {
    margin: 0 10px 0;
    flex: 1;
  }
`

const PlanLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 45px;

  background-color: ${({pro}) => (!pro ? 'var(--light-grey)' : 'var(--green)')};
  color: ${({pro}) => (!pro ? 'var(--dark-grey)' : 'var(--white)')};
  font-family: var(--secondary-font);
  font-size: 1em;
  font-weight: 600;
`

const PlanPrice = styled.div`
  margin: 25px 0;

  font-size: 4rem;
  font-weight: bold;
  font-family: var(--primary-font);

  span {
    color: var(--dark-grey);
    font-size: 0.9rem;
    font-weight: bold;
  }
`

const Proposition = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 25px;

  color: ${({disabled}) => (disabled ? `var(--dark-grey)` : `var(--black)`)};

  svg {
    opacity: ${({disabled}) => (disabled ? 0 : 1)};
  }
`

const StyledCheck = styled(Check)`
  margin: 10px;
`

const FinePrint = styled.p`
  color: var(--dark-grey);
  font-size: 0.9em;

  opacity: ${({disabled}) => (disabled ? 0 : 1)};
`

const DiscountSection = styled(BasicSection)`
  padding: 50px 0;
  background-color: var(--white);
`

const DiscountContent = styled(BasicContent)`
  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: row;

    max-width: var(--reading-width);
  }
`

const StyledBackpack = styled(Backpack)`
  @media only screen and (min-width: 700px) {
    flex: 1;
  }
`

const DiscountDescription = styled.div`
  @media only screen and (min-width: 700px) {
    margin-left: 30px;
    flex: 2;
  }
`

const TargetSection = styled(BasicSection)`
  padding: 50px 0;
`

const TargetContent = styled(BasicContent)``

const Targets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 25px 0;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const TargetBlurb = styled.div`
  text-align: center;

  margin: 25px 0;

  @media only screen and (min-width: 700px) {
    max-width: 300px;
    margin: 25px;
  }
`

const ComparisonSection = styled(BasicSection)`
  padding: 50px 0;
  background-color: var(--white);
`

const ComparisonContent = styled(BasicContent)``

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 900px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`

const Divider = styled.div`
  border-radius: 1px;
  width: 90%;
  height: 2px;
  margin: 25px 0;
  background-color: var(--light-grey);

  @media only screen and (min-width: 900px) {
    width: 2px;
    height: 800px;
    margin: 0 25px;
    align-self: center;
  }
`

const ColumnTitle = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
  svg {
    flex-shrink: 0;
  }
`

const triangleBlock = css`
  margin: 0 0.5em -0.3em 0;
`

const StyledTriangleBlockFull = styled(TriangleBlockFull)`
  ${triangleBlock}
`

const StyledTriangleBlockHalf = styled(TriangleBlockHalf)`
  ${triangleBlock}
`

const ColumnTitleText = styled.div`
  h3 {
    margin-bottom: 0;
  }
`

const ColumnEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.1rem;
  font-weight: 600;

  svg {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
`

const SpaceSection = styled(BasicSection)`
  position: relative;
  overflow: hidden;
  padding: 75px 0 110px;
  background-color: var(--dark-blue);
  color: var(--white);

  @media only screen and (min-width: 1200px) {
    padding: 25px 0;
  }
`

const SpaceContent = styled(BasicContent)`
  position: relative;
  align-items: flex-start;

  h2 {
    margin-top: 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: var(--content-width);

    div {
      max-width: 70%;
    }
  }
`

const StyledStarsMobile = styled(Stars1)`
  position: absolute;
  top: 20px;
  right: 20px;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`

const StyledStarsDesktop = styled(Stars1)`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: unset;
    position: absolute;
    top: 40%;
    left: -80px;
  }
`

const StyledAsteroidMobile = styled(AsteroidMobile)`
  position: absolute;
  bottom: -20%;
  right: 2%;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`

const StyledAsteroidDesktop = styled(AsteroidDesktop)`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: unset;
    position: absolute;
    right: 0;
  }
`

const FAQSection = styled(BasicSection)`
  padding-bottom: 50px;
`

const FAQContent = styled(BasicContent)`
  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const Question = styled.div`
  width: 100%;
`

const CallToActionSection = styled(BasicSection)`
  padding: 50px 0;
  background: center / cover no-repeat url(${BackgroundSVG});
  background-color: var(--white);
`

const CallToActionContent = styled(BasicContent)`
  text-align: center;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const HintText = styled.p`
  color: var(--dark-grey);
  font-size: 0.9em;
`

const PricingPage = () => {
  const {user, isLoading} = useAuth()
  const {logEvent} = useAmplitude()
  const [rate, setRate] = useState('monthly')

  const logClick = (buttonName, data = {}) => logEvent('pricing-page-button-click', {buttonName, ...data})

  return (
    <MainLayout theme='transparent'>
      <SEO
        title='Pricing'
        description='Are you ready to level up your programming skills? If you want to apply your skills to building fun, practical projects, the creator plan is for you.'
      />
      <PricesSection>
        <PricesContent>
          <SectionTitleDiv>
            <h2>
              Are you ready to level up your programming skills?
              <br />
              Start building today.
            </h2>
            <p>
              If you want to apply your skills to building fun, practical projects, this is the plan for you. Includes{' '}
              <strong>every project</strong> we offer plus <strong>every piece of content</strong> we’ve ever recorded
              (200+ videos).
            </p>
          </SectionTitleDiv>
          <RateToggle>
            <DiscountArrow />
            <RateButtonLeft isSelected={rate === 'annual'} onClick={() => setRate('annual')}>
              Pay Yearly
            </RateButtonLeft>
            <RateButtonRight isSelected={rate === 'monthly'} onClick={() => setRate('monthly')}>
              Pay Monthly
            </RateButtonRight>
          </RateToggle>
          <PlansBox>
            {isLoading ? (
              <LoadingSection>
                <LoadingSpinner />
              </LoadingSection>
            ) : (
              <>
                {user === null && (
                  <PlanCard>
                    <PlanLabel>FREE PLAN</PlanLabel>
                    <PlanPrice>
                      $0<span>/{rate === 'monthly' ? 'month' : 'year'}</span>
                    </PlanPrice>
                    <Proposition>
                      <StyledCheck />
                      <p>
                        <strong>Access to Free Content</strong>
                        <br />3 projects, 25 lessons
                      </p>
                    </Proposition>
                    <Proposition>
                      <StyledCheck />
                      <p>
                        <strong>Public Discord Community </strong>
                        <br />
                        Get help from our community
                      </p>
                    </Proposition>
                    <Proposition disabled>
                      <StyledCheck />
                      <p>
                        <strong>Get Certified</strong>
                        <br />
                        Receive a certificate of completion
                      </p>
                    </Proposition>
                    <Proposition disabled>
                      <StyledCheck />
                      <p>
                        <strong>Monthly New Content</strong>
                        <br />
                        Full access to new projects
                      </p>
                    </Proposition>
                    <LinkButton tier='secondary' to='/app/get-started' onClick={() => logClick('start-learning')}>
                      Start Learning
                    </LinkButton>
                    <FinePrint disabled>I{`'`}m invisible</FinePrint>
                  </PlanCard>
                )}
                <PlanCard>
                  <PlanLabel pro>CREATOR PLAN</PlanLabel>
                  <PlanPrice>
                    ${rate === 'monthly' ? 9 : 90}
                    <span>/{rate === 'monthly' ? 'month' : 'year'}</span>
                  </PlanPrice>
                  <Proposition>
                    <StyledCheck />
                    <p>
                      <strong>Full Access to Content</strong>
                      <br />
                      10+ projects, 250+ lessons
                    </p>
                  </Proposition>
                  <Proposition>
                    <StyledCheck />
                    <p>
                      <strong>Public Discord Community </strong>
                      <br />
                      Get help from our community
                    </p>
                  </Proposition>
                  <Proposition>
                    <StyledCheck />
                    <p>
                      <strong>Get Certified</strong>
                      <br />
                      Receive a certificate of completion
                    </p>
                  </Proposition>
                  <Proposition>
                    <StyledCheck />
                    <p>
                      <strong>Monthly New Content</strong>
                      <br />
                      Full access to new projects
                    </p>
                  </Proposition>
                  {user === null ? (
                    <LinkButton
                      tier='primary'
                      to={'/app/get-started'}
                      state={{successUrl: `/app/checkout?plan=${rate}&currency=usd`}}
                      buttoncolor='var(--green)'
                      activecolor='var(--green-active)'
                      onClick={() => logClick('upgrade')}
                    >
                      Become a Creator
                    </LinkButton>
                  ) : (
                    <LinkButton
                      tier='primary'
                      to={`/app/checkout?plan=${rate}&currency=usd`}
                      buttoncolor='var(--green)'
                      activecolor='var(--green-active)'
                      onClick={() => logClick('upgrade')}
                    >
                      Become a Creator
                    </LinkButton>
                  )}
                  <FinePrint>100% money back guarantee</FinePrint>
                </PlanCard>
              </>
            )}
          </PlansBox>
        </PricesContent>
      </PricesSection>
      <DiscountSection>
        <DiscountContent>
          <StyledBackpack />
          <DiscountDescription>
            <EyebrowText>FOR STUDENTS</EyebrowText>
            <h2>The Codex is 33% off for students.</h2>
            <p>
              As students, we know how valuable hands-on experience can be. The Codex is here to support you and your
              endeavours. It’s time to build!
            </p>
            <AnchorButton
              tier='primary'
              href='https://airtable.com/shrZEXJa5U4l47xYx'
              target='_blank'
              rel='noopener noreferrer'
            >
              Get Discount
            </AnchorButton>
          </DiscountDescription>
        </DiscountContent>
      </DiscountSection>
      <Testimonials single />
      <TargetSection>
        <TargetContent>
          <SectionTitleDiv>
            <EyebrowText>THINK IT. BUILD IT. SHIP IT.</EyebrowText>
            <h2>The Codex was made for creators just like you.</h2>
          </SectionTitleDiv>
          <Targets>
            <TargetBlurb>
              <PersonCareer />
              <p>
                <strong>Struggling to land a job?</strong>
              </p>
              <p>I want to stand out among peers when applying to jobs and showcase my skills to future employers.</p>
            </TargetBlurb>
            <TargetBlurb>
              <PersonStudent />
              <p>
                <strong>Confused in Class?</strong>
              </p>
              <p>Theory-filled lectures isn’t what CS is supposed to be. Build fun, engaging projects.</p>
            </TargetBlurb>
            <TargetBlurb>
              <PersonPassion />
              <p>
                <strong>Want to build your ideas?</strong>
              </p>
              <p>Start from any of our projects and build on top of them to achieve your idea.</p>
            </TargetBlurb>
          </Targets>
        </TargetContent>
      </TargetSection>
      <ComparisonSection>
        <ComparisonContent>
          <SectionTitleDiv>
            <EyebrowText>DISCOVER YOUR PROGRAMMING SUPERPOWERS</EyebrowText>
            <h2>What is included as a Creator?</h2>
          </SectionTitleDiv>
          <Columns>
            <div>
              <ColumnTitle>
                <StyledTriangleBlockHalf />
                <ColumnTitleText>
                  <h3>Free Plan</h3>
                  <p>Just the basics. Limited free projects and content only.</p>
                </ColumnTitleText>
              </ColumnTitle>
              <ColumnEntry>
                <p>Visualizing COVID-19 with Pandas & MatPlotLib</p>
                <CheckCircle checked color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Speech Recognition with Python and Flask</p>
                <CheckCircle checked color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Predicting Stock Prices with Machine Learning</p>
                <CheckCircle checked color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Bitcoin Cryptocurrency Price Visualization</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Song Lyrics Generator with Markov Chains</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Amazon Prime Video Selenium Scraper</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Recognizing Handwritten Digits with Python</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Sentiment Analysis of Stock News with Python</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Trump Tweet Analysis and Visualization</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>U.S. Presidential Polling Dashboard</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Weather API Dashboard with Flask</p>
                <CheckCircle color='var(--medium-grey)' />
              </ColumnEntry>
            </div>
            <Divider />
            <div>
              <ColumnTitle>
                <StyledTriangleBlockFull />
                <ColumnTitleText>
                  <h3>Creator Plan</h3>
                  <p>Access to 10+ projects, 250+ lessons, and newly released content in AL/ML.</p>
                </ColumnTitleText>
              </ColumnTitle>
              <ColumnEntry>
                <p>Visualizing COVID-19 with Pandas & MatPlotLib</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Speech Recognition with Python and Flask</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Predicting Stock Prices with Machine Learning</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Bitcoin Cryptocurrency Price Visualization</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Song Lyrics Generator with Markov Chains</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Amazon Prime Video Selenium Scraper</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Recognizing Handwritten Digits with Python</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Sentiment Analysis of Stock News with Python</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Trump Tweet Analysis and Visualization</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>U.S. Presidential Polling Dashboard</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>Weather API Dashboard with Flask</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>New Projects Every Month!</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
              <ColumnEntry>
                <p>250+ video lessons on hot Python topics</p>
                <CheckCircle checked color='var(--green)' />
              </ColumnEntry>
            </div>
          </Columns>
        </ComparisonContent>
      </ComparisonSection>
      <SpaceSection>
        <SpaceContent>
          <StyledStarsDesktop />
          <div>
            <p>
              <span role='img' aria-label='*rocket*'>
                🚀
              </span>{' '}
              Content literally out of this world{' '}
              <span role='img' aria-label='*rocket*'>
                🚀
              </span>
            </p>
            <h2>
              Creators have access to 70+ hours of content...
              <br />
              that’s enough to last you a trip to the moon!
            </h2>
          </div>
          <StyledAsteroidDesktop />
        </SpaceContent>
        <StyledStarsMobile />
        <StyledAsteroidMobile />
      </SpaceSection>
      <FAQSection>
        <FAQContent>
          <SectionTitleDiv>
            <EyebrowText>LET{`'`}S RM -RF YOUR DOUBTS</EyebrowText>
            <h2>Common Questions</h2>
          </SectionTitleDiv>
          <Question>
            <h3>Do you offer discounts?</h3>
            <p>
              Yes. You’ll get <strong>two months free</strong> when you pay for a year up front. If you are a student,{' '}
              <strong>get 33% off</strong> your monthly and yearly plans. Fill out{' '}
              <AnchorButton
                tier='tertiary'
                href='https://airtable.com/shrZEXJa5U4l47xYx'
                target='_blank'
                rel='noopener noreferrer'
              >
                this form
              </AnchorButton>{' '}
              to be emailed the discount code. Contact us if you are a non-profit.
            </p>
          </Question>
          <Question>
            <h3>Do I need coding experience before signing up?</h3>
            <p>
              true == false
              <br />
              Joke for the coders there. The answer is no. You don’t need any coding experience before signing up.
            </p>
          </Question>
          <Question>
            <h3>Why is The Codex better than Youtube or Udemy?</h3>
            <p>
              To put it simply, The Codex is focused on helping students become creators. We{`'`}re not about watching
              hours of boring lectures. You{`'`}ll build projects through The Codex to help you standout on your resume,
              land a job, and even bring your ideas to life.
            </p>
          </Question>
          <Question>
            <h3>What content can I start doing for free?</h3>
            <p>
              Our free plan gives you three full projects to build. Explore{' '}
              <LinkButton tier='tertiary' to='/projects'>
                here
              </LinkButton>
              .{/* TODO: make this button link to the projects page with a filter to show only free projects */}
            </p>
          </Question>
          <Question>
            <h3>I have a different question...</h3>
            <p>
              We’d be happy to answer them. Email us at{' '}
              <AnchorButton tier='tertiary' href='mailto:team@thecodex.me'>
                team@thecodex.me
              </AnchorButton>
              .
            </p>
          </Question>
        </FAQContent>
      </FAQSection>
      {user === null && (
        <CallToActionSection>
          <CallToActionContent>
            <h2>
              Are you ready to build?
              <br />
              Start shipping today
            </h2>
            <LinkButton to='/app/get-started' tier='primary' onClick={() => logClick('get-started')}>
              Aye, aye, captain.
            </LinkButton>
            <HintText>ha, get it? cause... ships.</HintText>
          </CallToActionContent>
        </CallToActionSection>
      )}
    </MainLayout>
  )
}

export default PricingPage
